<template>
    <div>
        <styled-progress-bar
            v-if="item.budgeting.monthlyBudget?.value && item.budgeting.spendToDate.value"
            height="10px"
            :color="(item.budgeting.spendToDate.value >= item.budgeting.monthlyBudget.value) ? 'red' : 'blue'"
            :value="item.budgeting.spendToDate.value / item.budgeting.monthlyBudget.value"
            arrows />
    </div>
</template>

<script>
import StyledProgressBar from '@/components/globals/StyledProgressBar';

export default {
    components: {
        StyledProgressBar
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    }
};
</script>
